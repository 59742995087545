<template>
  <div class="grid grid-4 gap res" v-if="files.length">
    <ClientAddEditPopup
      v-if="popupTriggers.popup == 'account'"
      :mode="popupTriggers.mode"
      :payload="popupTriggers.payload"
    ></ClientAddEditPopup>

    <div class="grid-center" v-for="file in files" :key="file.id">
      <h3 class="title">{{ file.company_name }}</h3>
      <div class="clearfix"></div>
      <div class="files">
        <div
          class="file-content"
          tabindex="0"
          @click="togglePopup('account', 'edit', file)"
          @keyup.enter="togglePopup('account', 'edit', file)"
        >
          <label class="file-text">{{ file.first_name }}</label>
          <div class="clearfix"></div>
          <label class="file-text">{{ file.email }}</label>
          <div class="clearfix"></div>
          <label class="file-text">{{
            `${file.address_line_1}, ${file.postal_code}`
          }}</label>
          <div class="clearfix"></div>
          <label class="file-text">{{ file.mobile_number }}</label>
          <div class="clearfix"></div>
          <h3 class="file-text">{{ file.company_name }}</h3>
        </div>
      </div>
    </div>
    <!-- <div class="grid-center">
      <h3 class="title">LMDB 75</h3>
      <div class="clearfix"></div>
      <div class="files">
        <div class="file-content">
          <label class="file-text">13 rue des epinettes 7017 Paris</label>
          <div class="clearfix"></div>
          <label class="file-text">06-23-31-24-68</label>
          <div class="clearfix"></div>
          <label class="file-text">06-23-31-24-68</label>
          <div class="clearfix"></div>
          <label class="file-text">Information</label>
          <div class="clearfix"></div>
          <h3 class="file-text">Charline</h3>
        </div>
      </div>
    </div> -->
  </div>
  <div v-else>
    <h4 class="lbl-gray__empty">{{ $t("message.this_list_is_empty") }}</h4>
  </div>
  <div class="clearfix"></div>
  <br />
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import ClientAddEditPopup from "./ClientAddEditPopup.vue";
import helper from "@/helper";
// import router from "@/router";
import { useRoute } from "vue-router";
export default {
  components: { ClientAddEditPopup },
  setup() {
    const router = useRoute();
    const popupTriggers = ref({
      popup: null,
      mode: "create",
      payload: null,
    });
    const store = useStore();

    let files = computed(() =>
      store.state.client.clients.filter((f) => f.status === "ACTIVE")
    );

    if (
      router.name === "ArchivedClients" ||
      router.name === "ArchivedSuppliers" ||
      router.name === "ArchivedEmployees"
    ) {
      files = computed(() =>
        store.state.client.clients.filter((f) => f.status === "ARCHIVED")
      );
    }
    const emitter = helper().emitter();

    const togglePopup = async (popup, mode, file) => {
      popupTriggers.value.mode = mode;
      popupTriggers.value.popup = popup;
      popupTriggers.value.payload = file;
    };

    onMounted(async () => {
      emitter.on("close-popup", () => {
        popupTriggers.value.popup = null;
        popupTriggers.value.mode = null;
      });
      if (!files.value?.length) {
        if (router.name == "MyClients" || router.name == "ArchivedClients") {
          await store.dispatch("client/fetchAllClients");
        } else if (
          router.name == "MySuppliers" ||
          router.name == "ArchivedSuppliers"
        ) {
          await store.dispatch("client/fetchAllSuppliers");
        } else if (
          router.name == "MyEmployees" ||
          router.name == "ArchivedEmployees"
        ) {
          await store.dispatch("client/fetchAllEmployees");
        }
      }
      store.commit("component/SET_LOADER", false);
    });

    onUnmounted(() => {
      store.state.client.clients = [];
    });

    return {
      files,
      // all_client,
      popupTriggers,
      togglePopup,
    };
  },
};
</script>
